<template>
  <div>
    <b-form-group
      :label="`${$getVisibleNames(
        'evaluations2.rubricperformancelevel',
        false,
        'Niveles de Logro'
      )}`"
      label-for="input-title"
      label-cols="4"
      label-cols-sm="3"
    >
      <b-form-input
        id="input-title"
        v-model="$v.rubric_achievement.title.$model"
        :state="validateState('title')"
        aria-describedby="input-title-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-title-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="4"
      label-cols-sm="3"
    >
      <div
        v-b-tooltip.v-secondary="
          `Editar Descripción del ${$getVisibleNames(
            'evaluations2.rubricperformancelevel',
            false,
            'Niveles de Logro'
          )}`
        "
        class="rubric_achievement-form-description rich-text-content mb-2"
        v-html="$v.rubric_achievement.description.$model"
        @click="
          $bvModal.show(
            `edit-rubric_achievement-description-modal-${rubric_achievement.id}`
          )
        "
      ></div>
      <b-modal
        :id="`edit-rubric_achievement-description-modal-${rubric_achievement.id}`"
        :title="`Editar Descripción del ${$getVisibleNames(
          'evaluations2.rubricperformancelevel',
          false,
          'Niveles de Logro'
        )}`"
        size="lg"
        hide-footer
        ignore-enforce-focus-selector="#sidebar-right"
      >
        <NewRichTextEditor
          :Object="rubric_achievement"
          :Text="rubric_achievement.description"
          @save="patchRubricAchievementDescription"
          @close="
            $bvModal.hide(
              `edit-rubric_achievement-description-modal-${rubric_achievement.id}`
            )
          "
        ></NewRichTextEditor>
      </b-modal>
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es opcional.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="`${
        rubric && rubric.show_percentage_sign ? 'Porcentaje' : 'Puntaje'
      }`"
      label-for="input-score"
      label-cols="4"
      label-cols-sm="3"
    >
      <b-form-input
        id="input-score"
        v-model="$v.rubric_achievement.score.$model"
        :state="validateState('score')"
        aria-describedby="input-score-feedback"
        size="sm"
        type="number"
      ></b-form-input>
      <b-form-invalid-feedback id="input-score-feedback">
        <span>Este campo debe ser mayor o igual a 0</span>
        <span v-if="rubric && rubric.show_percentage_sign">
          y menor a o igual a 100.
        </span>
      </b-form-invalid-feedback>
    </b-form-group>

    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(rubric_achievement.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="deleteRubricAchievement"
          >Eliminar</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="saveRubricAchievement"
          >Guardar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minValue } from "vuelidate/lib/validators";

export default {
  name: "RubricAchievementForm",
  mixins: [validationMixin],
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
  },
  props: {
    rubric_id: {
      type: Number,
      required: true,
    },
    order: {
      type: Number,
    },
    RubricAchievement: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          title: "",
          description: "",
          score: 0,
          order: this.order,
          rubric: this.rubric_id,
        };
      },
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rubric_achievement: { ...this.RubricAchievement },
    };
  },
  validations: {
    rubric_achievement: {
      title: {
        required,
      },
      description: {},
      score: {
        required,
        minValue: minValue(0),
        isValid() {
          if (this.rubric && this.rubric.show_percentage_sign) {
            if (this.rubric_achievement.score > 100) return false;
            else return true;
          } else return true;
        },
      },
      rubric: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      rubrics: names.NEW_RUBRIC_INSTRUMENTS,
    }),
    rubric() {
      return this.rubrics.find((x) => x.id == this.rubric_id);
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.rubric_achievement[key];
      return $dirty ? !$error : null;
    },
    saveRubricAchievement() {
      this.$v.rubric_achievement.$touch();
      if (this.$v.rubric_achievement.$anyError) {
        return;
      }
      if (isNaN(this.rubric_achievement.id)) this.createRubricAchievement();
      else this.updateRubricAchievement();
    },
    createRubricAchievement() {
      this.$store
        .dispatch(names.POST_NEW_RUBRIC_ACHIEVEMENT, this.rubric_achievement)
        .then((response) => {
          this.rubric_achievement = response;
          this.$emit("created", this.rubric_achievement);
        });
    },
    updateRubricAchievement() {
      this.$store
        .dispatch(names.UPDATE_NEW_RUBRIC_ACHIEVEMENT, this.rubric_achievement)
        .then((response) => {
          this.rubric_achievement = response;
          this.$emit("updated", this.rubric_achievement);
        });
    },
    patchRubricAchievementDescription(object, text) {
      this.rubric_achievement.description = text;
    },
    deleteRubricAchievement() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "evaluations2.rubricperformancelevel",
          false,
          "Nivel de Logro"
        )}" de la Rúbrica?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              names.DELETE_NEW_RUBRIC_ACHIEVEMENT,
              this.rubric_achievement.id
            )
            .then(() => {
              this.$emit("deleted", this.rubric_achievement);
            });
        }
      });
    },
  },
  mounted() {},
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.rubric_achievement-form-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  max-height: 200px;
  overflow: auto;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
.rubric_achievement-form-description >>> p {
  margin-bottom: 0.7rem !important;
}
</style>