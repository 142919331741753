var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form-group',{attrs:{"label":`${_vm.$getVisibleNames(
      'evaluations2.rubricperformancelevel',
      false,
      'Niveles de Logro'
    )}`,"label-for":"input-title","label-cols":"4","label-cols-sm":"3"}},[_c('b-form-input',{attrs:{"id":"input-title","state":_vm.validateState('title'),"aria-describedby":"input-title-feedback","size":"sm"},model:{value:(_vm.$v.rubric_achievement.title.$model),callback:function ($$v) {_vm.$set(_vm.$v.rubric_achievement.title, "$model", $$v)},expression:"$v.rubric_achievement.title.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-title-feedback"}},[_vm._v("Este campo es obligatorio.")])],1),_c('b-form-group',{attrs:{"label":"Descripción","label-for":"input-description","label-cols":"4","label-cols-sm":"3"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(
        `Editar Descripción del ${_vm.$getVisibleNames(
          'evaluations2.rubricperformancelevel',
          false,
          'Niveles de Logro'
        )}`
      ),expression:"\n        `Editar Descripción del ${$getVisibleNames(\n          'evaluations2.rubricperformancelevel',\n          false,\n          'Niveles de Logro'\n        )}`\n      ",modifiers:{"v-secondary":true}}],staticClass:"rubric_achievement-form-description rich-text-content mb-2",domProps:{"innerHTML":_vm._s(_vm.$v.rubric_achievement.description.$model)},on:{"click":function($event){return _vm.$bvModal.show(
          `edit-rubric_achievement-description-modal-${_vm.rubric_achievement.id}`
        )}}}),_c('b-modal',{attrs:{"id":`edit-rubric_achievement-description-modal-${_vm.rubric_achievement.id}`,"title":`Editar Descripción del ${_vm.$getVisibleNames(
        'evaluations2.rubricperformancelevel',
        false,
        'Niveles de Logro'
      )}`,"size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Object":_vm.rubric_achievement,"Text":_vm.rubric_achievement.description},on:{"save":_vm.patchRubricAchievementDescription,"close":function($event){return _vm.$bvModal.hide(
            `edit-rubric_achievement-description-modal-${_vm.rubric_achievement.id}`
          )}}})],1),_c('b-form-invalid-feedback',{attrs:{"id":"input-description-feedback"}},[_vm._v("Este campo es opcional.")])],1),_c('b-form-group',{attrs:{"label":`${
      _vm.rubric && _vm.rubric.show_percentage_sign ? 'Porcentaje' : 'Puntaje'
    }`,"label-for":"input-score","label-cols":"4","label-cols-sm":"3"}},[_c('b-form-input',{attrs:{"id":"input-score","state":_vm.validateState('score'),"aria-describedby":"input-score-feedback","size":"sm","type":"number"},model:{value:(_vm.$v.rubric_achievement.score.$model),callback:function ($$v) {_vm.$set(_vm.$v.rubric_achievement.score, "$model", $$v)},expression:"$v.rubric_achievement.score.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-score-feedback"}},[_c('span',[_vm._v("Este campo debe ser mayor o igual a 0")]),(_vm.rubric && _vm.rubric.show_percentage_sign)?_c('span',[_vm._v(" y menor a o igual a 100. ")]):_vm._e()])],1),_c('div',{staticClass:"row"},[(_vm.show_delete_button && !isNaN(_vm.rubric_achievement.id))?_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.deleteRubricAchievement}},[_vm._v("Eliminar")])],1):_vm._e(),_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":_vm.saveRubricAchievement}},[_vm._v("Guardar")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }